import axios, { AxiosError, AxiosInstance } from "axios";
import * as AxiosLogger from "axios-logger";
import { StatusCodes } from "http-status-codes";
import axiosRetry from "axios-retry";

/* global console, process */

/**
 * @constant
 * Maximum number of retries, not counting the initial request.
 */
const MAX_RETRIES = 5;

/**
 * Exponential, truncated backoff wih jitter.
 *
 * Uses the algorithm described in
 * https://cloud.google.com/iot/docs/how-tos/exponential-backoff
 */

// The maximum backoff time, in seconds.
const MAXIMUM_BACKOFF_TIME = 64;

/**
 * Computes the amount of time to wait before retry.
 *
 * @argument {number} n The retry attempt. n = 1 equals the first retry.
 * @returns {number} The number of milliseconds to back off.
 */
const truncatedExpDelayMillis = (n: number): number => {
  const delay = 1000 * (n ** 2 + Math.random());
  return Math.min(delay, MAXIMUM_BACKOFF_TIME * 1000);
};

/**
 * Can this HTTP error be retried?
 *
 * @param {AxiosError} error The error returned by axios.
 * @returns {boolean} True if the request can be retried.
 */
const isRetryableError = (error: AxiosError): boolean => {
  // We are conservative and only retry SERVICE_UNAVAILABLE (503). While all 5xx
  // status codes are server failures that could potentially be retried it
  // could be dangerous to retry e.g. 500, as a broken server implementation
  // could perform some side-effect before returning a 500.
  return (
    error.code !== "ETIMEDOUT" && // Prevents retrying timed out requests
    (!error.response || error.response.status === StatusCodes.SERVICE_UNAVAILABLE)
  );
};

type Props = {
  /**
   * Whether to enable debug logging for this Axios instance.
   */
  debug?: boolean;
};

export const connectAxios = ({ debug = true }: Props): AxiosInstance => {
  const connect = axios.create({
    baseURL: `https://${process.env.STAGE}-connect.beneticsapi.com`,
    transitional: { clarifyTimeoutError: true },
  });
  if (debug) {
    connect.interceptors.response.use(AxiosLogger.responseLogger, AxiosLogger.errorLogger);

    connect.interceptors.request.use(
      (req) => {
        const headers = {
          ...req.headers,
        };
        const logMessage =
          `[Axios][Request] ${new Date().toISOString()} | ` +
          `Request: ${req.method!.toUpperCase()} | ` +
          `Headers: ${JSON.stringify(headers)}`;
        console.debug(logMessage);
        return req;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  axiosRetry(connect, {
    retries: MAX_RETRIES,
    retryCondition: isRetryableError,
    retryDelay: truncatedExpDelayMillis,
  });

  return connect;
};
