import {
  makeStyles,
  Card,
  CardHeader,
  Subtitle2Stronger,
  Body1,
  Caption1,
  Button,
} from "@fluentui/react-components";
import { OutlookProject, OutlookProjects } from "../../models/v1";
import React from "react";
import { MoreVertical16Regular } from "@fluentui/react-icons";

interface ProjectCardsProps {
  projects: OutlookProjects;
}

interface ProjectCardProps {
  project: OutlookProject;
}

const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "8px",
  },
  cardHeader: {},
});

const ProjectCard = ({ project }: ProjectCardProps) => {
  const styles = useStyles();
  return (
    <Card
      className={styles.card}
      size="small"
      appearance="filled"
      orientation="vertical"
    >
      <CardHeader
        className={styles.cardHeader}
        action={
          <Button appearance="transparent" icon={<MoreVertical16Regular />} />
        }
        header={<Subtitle2Stronger>{project.name}</Subtitle2Stronger>}
        description={
          <div>
            <Body1>{project.address}</Body1>
            <br />
            <Caption1>ID: {project.id}</Caption1>
          </div>
        }
      />
    </Card>
  );
};

const ProjectCards = ({ projects }: ProjectCardsProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {projects.projects.map((project, index) => (
        <div key={index}>
          <ProjectCard project={project} />
        </div>
      ))}
    </div>
  );
};

export default ProjectCards;
